/* eslint-disable no-new-wrappers */
import { decode } from 'html-entities';
import PropTypes from 'prop-types';

// Material-UI
import DeleteIcon from '@mui/icons-material/Delete';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system';

// Utils & Consts
import { RESOLUTION } from 'constants/image-resolution';
import { LIST_ITEM_FALLBACK } from 'constants/images-defaults';
import { splitPrice, transformImageUrl } from 'utils/functions';
import { getImageOrDefault } from 'utils/products';

/** Components */
import AddToCartPopoverWithVariation from 'components/Cart/AddToCartPopover/AddToCartPopoverWithVariations';
import AddToCartPopoverWithoutVariations from 'components/Cart/AddToCartPopover/AddToCartPopoverWithoutVariations';
import ConditionalWrap from 'components/ConditionalWrap';
import CustomImage from 'components/CustomImage';
import VirgoFlair from 'components/Flair';
import QuantitySelector from 'components/QuantitySelector';
import WishListButton from 'components/WishListButton';
import { useMemo } from 'react';
import { Button } from '@mui/material';

const styles = {
  root: {
    width: 314,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    height: 64,
    WebkitLineClamp: 2 /* number of lines to show */,
    overflow: 'hidden',
    display: 'WebkitBox',
    textOverflow: 'ellipsis',
    WebkitBoxOrient: 'vertical',
  },
  brandArea: {
    marginTop: '-0.25rem',
    marginBottom: '-0.25rem',
  },
  actions: {
    display: 'flex',
    marginTop: '-1rem',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
    marginBottom: '-1.5rem',
    justifyContent: 'space-between',
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
    overflow: 'hidden',
  },
  price: {
    display: 'flex',
    marginTop: '-0.5rem',
    alignItems: 'center',
    marginBottom: '-0.5rem',
    justifyContent: 'flex-start',
  },
  avatar: {
    bgcolor: grey[400],
  },
  strike: {
    textDecoration: 'line-through',
    textDecorationStyle: 'solid' /* solid/double/dotted/dashed */,
    fontSize: '0.75rem',
    textDecorationColor: 'rgba(0,0,0,0.35)',
  },
  buttonWrapper: {
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
  },
};

const CardTitle = styled(CardContent)(() => ({
  height: 64,
  WebkitLineClamp: 2 /* number of lines to show */,
  overflow: 'hidden',
  display: 'WebkitBox',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const legacyProductCardDesktopLayoutDefaultPropSx = {};

const LegacyProductCardDesktopLayout = ({
  sx = legacyProductCardDesktopLayoutDefaultPropSx,
  product,
  index,
  popoverProduct = null,
  addToCartButtonRef,
  currencySymbol,
  conditionalWrapConfig,
  productPrice,
  unAuthenticatedButton,
  selectedRRP,
  quantityToReset = null,
  selectedMinOrderValue,
  inCart,
  stockError,
  setStockError,
  inApiCall,
  inWishlist,
  hasVariations,
  isAddingToCart,
  isAuthenticated,
  isSelectingQuantity,
  showAddToCartPopover,
  productSubtotal,
  handleOnAddToCart,
  wholesalerSummary,
  handleAddToWishlist,
  handleRedirectToHref,
  handleRemoveFromCart,
  handleRedirectToBrand,
  quantitySelectorHelper,
  handleRemoveFromWishlist,
  handleAddToCartPopoverClose,
  handleOnAddToCartWithVariation,
  handleProductAlreadyAddedToCart,
  algoliaQueryID = '',
}) => {
  const theme = useTheme();
  const isCurrentPopoverProduct = product?.id === popoverProduct?.id && showAddToCartPopover;
  const { flairs } = product;

  const stockErrorMessage = stockError?.find(
    (error) => error?.productId === popoverProduct?.id && showAddToCartPopover
  );

  // Stock and pack data
  const minimumOrderQuantity = useMemo(
    () => Math.max(product?.productMinQuantity || 1, 1),
    [product?.productMinQuantity]
  );
  const maxStockAvailable = useMemo(
    () =>
      product?.productInventory?.productInventoryTracked === 'yes' ? product?.productInventory?.productStock || 0 : 0,
    [product?.productInventory?.productInventoryTracked, product?.productInventory?.productStock]
  );
  const productOrderQuantity = useMemo(
    () => Math.max(product?.productOrderQuantity || 1, 1),
    [product?.productOrderQuantity]
  );

  return (
    <Card sx={{ ...styles.root, ...sx }} data-testid="product-card">
      <CardActionArea sx={{ position: 'relative' }} component="div">
        {flairs?.length > 0 && (
          <VirgoFlair
            flair={flairs[0]}
            sx={{
              position: 'absolute',
              top: theme.spacing(1),
              right: theme.spacing(1),
            }}
          />
        )}
        <WishListButton {...{ inWishlist, handleRemoveFromWishlist, handleAddToWishlist }} />

        <ConditionalWrap {...conditionalWrapConfig}>
          <CustomImage
            layout="responsive"
            width="314"
            height="314"
            sizes="100vw"
            alt={product.productName}
            onClick={handleRedirectToHref}
            priority={index < 4}
            fetchPriority={index < 4 ? 'high' : 'auto'}
            loading={index < 4 ? 'eager' : 'lazy'}
            fallbackSrc={LIST_ITEM_FALLBACK}
            src={
              product.productThumbnailUrl?.productThumbnailBaseUrl
                ? transformImageUrl(
                    product.productThumbnailUrl?.productThumbnailBaseUrl,
                    product.productThumbnailUrl?.productThumbnailName,
                    RESOLUTION.CARD.DESKTOP
                  )
                : getImageOrDefault({
                    base: product.productThumbnailUrl?.productThumbnailBaseUrl,
                    path: product.productThumbnailUrl?.productThumbnailName,
                  })
            }
            data-testid="product-card-image"
          />
        </ConditionalWrap>
        <ConditionalWrap {...conditionalWrapConfig}>
          <CardTitle>
            <Typography
              mt={-0.75}
              gutterBottom
              component="h3"
              variant="h6"
              color="textPrimary"
              data-testid="product-card-title"
            >
              {decode(product.productName?.substring(0, Math.min(product.productName?.length, 48)))}
            </Typography>
          </CardTitle>
        </ConditionalWrap>
      </CardActionArea>
      {/* Seller / Wholesaler Info */}
      {product?.wholesaler && (
        <CardActionArea onClick={handleRedirectToBrand}>
          <CardHeader
            sx={styles.brandArea}
            data-testid="product-card-header"
            avatar={
              <Avatar
                alt={product.wholesaler?.name}
                sx={styles.avatar}
                aria-label={product.wholesaler?.name}
                src={transformImageUrl(
                  product.wholesaler?.logo?.wholesalerStoreLogoBasePath,
                  product.wholesaler?.logo?.wholesalerStoreLogoImage,
                  RESOLUTION.LOGO.DESKTOP,
                  true
                )}
              />
            }
            subheader={`${product?.wholesaler?.country}, Minimum ${currencySymbol}${selectedMinOrderValue}`}
            title={<span className="notranslate">{product?.wholesaler?.name}</span>}
          />
        </CardActionArea>
      )}
      {isAuthenticated ? (
        <>
          <CardContent sx={styles.actions}>
            <CardContent sx={{ marginLeft: '-0.5rem', pr: 0 }}>
              {productPrice}
              <Typography
                color="textSecondary"
                variant="body2"
                className="priceTag"
                sx={{ fontSize: '11px' }}
                data-testid="rrp-typography"
              >
                <span className="notranslate">
                  {hasVariations ? 'RRP from' : 'RRP'} {currencySymbol}
                  {splitPrice(selectedRRP).price}.<span className="priceCent">{splitPrice(selectedRRP).floating}</span>
                </span>
              </Typography>
            </CardContent>
            <CardActions data-testid="card-actions" sx={styles.buttonWrapper} ref={addToCartButtonRef}>
              {hasVariations ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOnAddToCartWithVariation}
                  disabled={isCurrentPopoverProduct}
                  data-testid="select-one-button"
                >
                  <span className="notranslate">{inCart ? 'Add More' : 'Select One'}</span>
                </Button>
              ) : (
                // Add More / Add to basket button for no variations product
                <Button
                  loading={isAddingToCart}
                  variant="contained"
                  color="primary"
                  onClick={inCart ? handleProductAlreadyAddedToCart : handleOnAddToCart}
                  disabled={isCurrentPopoverProduct}
                  data-testid="add-to-cart-button"
                >
                  <span className="notranslate">{inCart ? 'Add More' : 'Add to Basket'}</span>
                </Button>
              )}
            </CardActions>
          </CardContent>

          {/* A2C Popover  */}
          {hasVariations
            ? isCurrentPopoverProduct && (
                <AddToCartPopoverWithVariation
                  stockError={stockError}
                  setStockError={setStockError}
                  anchorEl={addToCartButtonRef?.current}
                  data-testid="add-to-cart-popover__variations"
                  handleAddToCartPopoverClose={handleAddToCartPopoverClose}
                  open={showAddToCartPopover && isCurrentPopoverProduct}
                  handleRedirectToBrand={handleRedirectToBrand}
                  algoliaQueryID={algoliaQueryID}
                />
              )
            : isCurrentPopoverProduct && (
                <AddToCartPopoverWithoutVariations
                  showBackdropLoader={inApiCall}
                  brandSubtotal={wholesalerSummary()?.total}
                  data-testid="add-to-cart-popover"
                  productSubtotal={productSubtotal()}
                  productOrderQuantity={productOrderQuantity}
                  anchorEl={addToCartButtonRef?.current}
                  handleRedirectToBrand={handleRedirectToBrand}
                  handleAddToCartPopoverClose={handleAddToCartPopoverClose}
                  open={isCurrentPopoverProduct}
                  brandMinOrderValue={selectedMinOrderValue * 100} // convert back to cents
                >
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <QuantitySelector
                      type="light"
                      sx={{
                        border: `1px solid ${alpha(theme.palette.grey[500], 0.32)}`,
                        borderRadius: '8px',
                      }}
                      initialValue={minimumOrderQuantity}
                      minimumValue={minimumOrderQuantity}
                      maximumValue={maxStockAvailable}
                      multipleOf={productOrderQuantity}
                      isDisabled={isSelectingQuantity}
                      quantityToReset={quantityToReset}
                      onChange={quantitySelectorHelper}
                      data-testid="quantity-selector"
                    />
                    {productOrderQuantity > 1 && (
                      <Typography variant="subtitle2" fontWeight="bold" data-testid="product-increment-message">
                        Sold in increments of {productOrderQuantity}
                      </Typography>
                    )}
                    <IconButton aria-label="delete cart item" onClick={handleRemoveFromCart}>
                      <DeleteIcon />
                    </IconButton>
                  </Stack>

                  {/* out of stock / stock quantity message */}
                  {stockErrorMessage && (
                    <Typography data-testid="stock-error-msg-A2CPopover" variant="body2" color="#d32f2f">
                      {stockErrorMessage?.message}
                    </Typography>
                  )}
                </AddToCartPopoverWithoutVariations>
              )}
        </>
      ) : (
        <CardActions sx={{ mt: 0.5, mb: 0.5, alignSelf: 'center' }} data-testid="card-actions">
          {unAuthenticatedButton}
        </CardActions>
      )}
    </Card>
  );
};

LegacyProductCardDesktopLayout.propTypes = {
  sx: PropTypes.shape({}),
  product: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    flairs: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string,
        flairTitle: PropTypes.string,
        flairBackgroundColor: PropTypes.string,
        flairTextColor: PropTypes.string,
        data: PropTypes.shape({}),
      })
    ),
    productSlug: PropTypes.string.isRequired,
    brandSlug: PropTypes.string.isRequired,
    productMinQuantity: PropTypes.number,
    productPrice: PropTypes.arrayOf(
      PropTypes.shape({
        productRRP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productWSP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productCurrency: PropTypes.string,
      })
    ),
    productDiscount: PropTypes.arrayOf(
      PropTypes.shape({
        productDiscountValue: PropTypes.number,
        productDiscountCurrency: PropTypes.string,
      })
    ),
    productThumbnailUrl: PropTypes.shape({
      productThumbnailName: PropTypes.string,
      productThumbnailBaseUrl: PropTypes.string,
    }),
    productVariationLabels: PropTypes.arrayOf(PropTypes.string),
    productName: PropTypes.string.isRequired,
    wholesaler: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      rating: PropTypes.string,
      id: PropTypes.string,
      wpId: PropTypes.string,
      logo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      minOrderValue: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
      storeDiscount: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreDiscountPercentage: PropTypes.number,
          wholesalerStoreDiscountMinOrderAmount: PropTypes.number,
        })
      ),
    }),
    productBulkDiscount: PropTypes.shape({
      productBulkDiscountPercentage: PropTypes.number,
      productBulkDiscountMinOrderQuantity: PropTypes.number,
    }),
    productInventory: PropTypes.shape({
      productStock: PropTypes.number,
      productInventoryTracked: PropTypes.string,
    }),
    productOrderQuantity: PropTypes.number,
  }).isRequired,
  index: PropTypes.number.isRequired,
  popoverProduct: PropTypes.shape({ id: PropTypes.string }),
  addToCartButtonRef: PropTypes.shape({ current: PropTypes.oneOfType([PropTypes.element, PropTypes.shape({})]) })
    .isRequired,
  conditionalWrapConfig: PropTypes.shape({
    condition: PropTypes.bool,
    getConditionalWrapper: PropTypes.func,
    getDefaultWrapper: PropTypes.func,
  }).isRequired,
  currencySymbol: PropTypes.string.isRequired,
  refProductUrl: PropTypes.string,
  productPrice: PropTypes.element.isRequired,
  unAuthenticatedButton: PropTypes.element.isRequired,
  selectedRRP: PropTypes.number.isRequired,
  quantityToReset: PropTypes.oneOfType([PropTypes.number, PropTypes.shape({})]),
  selectedMinOrderValue: PropTypes.number.isRequired,
  inCart: PropTypes.bool.isRequired,
  inApiCall: PropTypes.bool.isRequired,
  inWishlist: PropTypes.bool.isRequired,
  hasVariations: PropTypes.bool.isRequired,
  isAddingToCart: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isSelectingQuantity: PropTypes.bool.isRequired,
  showAddToCartPopover: PropTypes.bool.isRequired,
  productSubtotal: PropTypes.func.isRequired,
  handleOnAddToCart: PropTypes.func.isRequired,
  wholesalerSummary: PropTypes.func.isRequired,
  handleAddToWishlist: PropTypes.func.isRequired,
  handleRedirectToHref: PropTypes.func.isRequired,
  handleRemoveFromCart: PropTypes.func.isRequired,
  stockError: PropTypes.arrayOf(
    PropTypes.shape({
      productId: PropTypes.string,
      message: PropTypes.string,
    })
  ).isRequired,
  setStockError: PropTypes.func.isRequired,
  handleRedirectToBrand: PropTypes.func.isRequired,
  quantitySelectorHelper: PropTypes.func.isRequired,
  handleRemoveFromWishlist: PropTypes.func.isRequired,
  handleAddToCartPopoverClose: PropTypes.func.isRequired,
  handleOnAddToCartWithVariation: PropTypes.func.isRequired,
  handleProductAlreadyAddedToCart: PropTypes.func.isRequired,
  algoliaQueryID: PropTypes.string,
};

export default LegacyProductCardDesktopLayout;
