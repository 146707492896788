/* eslint-disable no-new-wrappers */
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { useDebounce, useLocalStorage } from 'react-use';
import { useState, useEffect, useRef, useCallback } from 'react';

// Material-UI
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

// Hooks
import useAuth from 'hooks/useAuth';
import useCart from 'hooks/useCart';
import { useTheme } from '@mui/material';
import useWishlist from 'hooks/useWishlist';
import useCurrency from 'hooks/useCurrency';
import useResponsive from 'hooks/useResponsive';
import useFeatureFlags from 'hooks/useFeatureFlags';
import useProductsVisited from 'hooks/useProductsVisited';
import useAddToCartPopover from 'hooks/useAddToCartPopover';
import useReferrals from 'hooks/useReferrals';

// Utils & Consts
import {
  noOp,
  splitPrice,
  getProductSubtotal,
  setPriceInDesiredCurrency,
  getCartItemsWithCommonWholesalerId,
} from 'utils/functions';
import WP_ROUTES from 'constants/wordpress-routes';
import { validateStockAmount } from 'utils/stockValidation';
import { getWholesalerSummary } from '@creoate/cart-lib/discounts';
import {
  GTMProductWishlistEvents,
  GTMProductEvents,
  GTMProductAddToCartEvents,
  pushAlgoliaClickEvent,
  pushAlgoliaAddToCartEvent,
} from 'utils/gtm';

// CMPS
import Link from 'components/Link/Link';
import useSignupPopup from 'hooks/useSignupPopup';
import LegacyProductCardMobileLayout from 'components/Product/LegacyProductCard/layouts/LegacyProductCardMobileLayout';
import LegacyProductCardDesktopLayout from 'components/Product/LegacyProductCard/layouts/LegacyProductCardDesktopLayout';

const styles = {
  root: {
    width: 314,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  brandArea: {
    marginTop: '-0.25rem',
    marginBottom: '-0.25rem',
  },
  actions: {
    display: 'flex',
    marginTop: '-1rem',
    marginLeft: '-0.5rem',
    marginRight: '-0.5rem',
    marginBottom: '-1.5rem',
    justifyContent: 'space-between',
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
    overflow: 'hidden',
  },
  price: {
    display: 'flex',
    marginTop: '-0.5rem',
    alignItems: 'center',
    marginBottom: '-0.5rem',
    justifyContent: 'flex-start',
  },
  avatar: {
    bgcolor: grey[400],
  },
  strike: {
    textDecoration: 'line-through',
    textDecorationStyle: 'solid' /* solid/double/dotted/dashed */,
    fontSize: '0.75rem',
    textDecorationColor: 'rgba(0,0,0,0.35)',
  },
  buttonWrapper: {
    '@media (min-width: 1200px) and (max-width: 1367px)': {
      whiteSpace: 'nowrap',
    },
  },
};

const CardTitle = styled(CardContent)(() => ({
  height: 64,
  WebkitLineClamp: 2 /* number of lines to show */,
  overflow: 'hidden',
  display: 'WebkitBox',
  textOverflow: 'ellipsis',
  WebkitBoxOrient: 'vertical',
}));

const legacyProductCardDefaultPropSx = {};

const legacyProductCardDefaultPropProduct = {
  id: '',
  WP_id: '',
  flair: {
    type: '',
    value: '',
  },
  productSlug: '',
  brandSlug: '',
  productMinQuantity: 0,
  productPrice: [
    {
      productRRP: '',
      productWSP: '',
      productCurrency: '',
    },
  ],
  productDiscount: [
    {
      productDiscountValue: 0,
      productDiscountCurrency: '',
    },
  ],
  productCategories: [],
  productThumbnailUrl: {
    productThumbnailName: '',
    productThumbnailBaseUrl: '',
  },
  productVariationLabels: [''],
  productName: '',
  wholesaler: {
    name: '',
    country: '',
    description: '',
    rating: '',
    id: '',
    wpId: '',
    logo: {
      wholesalerStoreLogoImage: '',
      wholesalerStoreLogoBasePath: '',
    },
    minOrderValue: [
      {
        wholesalerStoreMinOrderAmount: 0,
        wholesalerStoreMinOrderCurrency: '',
      },
    ],
    storeDiscount: [
      {
        wholesalerStoreDiscountPercentage: 0,
        wholesalerStoreDiscountMinOrderAmount: 0,
      },
    ],
  },
  productBulkDiscount: {
    productBulkDiscountPercentage: 0,
    productBulkDiscountMinOrderQuantity: 0,
  },
};

const LegacyProductCard = ({
  sx = legacyProductCardDefaultPropSx,
  product = legacyProductCardDefaultPropProduct,
  loading = false,
  handleItemIdChange = noOp,
  forceDesktop = false,
  index,
  algoliaQueryID = null,
}) => {
  const router = useRouter();
  const isMobile = useResponsive('down', 'sm');
  const isMobileLayout = isMobile && !forceDesktop;
  const { isAuthenticated, userId } = useAuth();
  const { currency, exchangeRates } = useCurrency();
  const { handleShowSignupPopup } = useSignupPopup();
  const { setProductsVisited, showSignupPopup } = useProductsVisited();
  const { wishlist, addToWishlist, removeFromWishlist } = useWishlist();
  const { cart, addToCart, removeFromCart, increaseInCart, decreaseInCart } = useCart();
  const [, setRedirectUrl] = useLocalStorage('redirect-url', '');
  const { queryParamsString } = useReferrals();

  const theme = useTheme();

  const isUnregistered = !isAuthenticated;

  /* -- A2C Popover -- */
  const addToCartButtonRef = useRef(null);
  const { popoverProduct, setPopoverProduct } = useAddToCartPopover();
  const [showAddToCartPopover, setShowAddToCartPopover] = useState(false);

  const [hasVariations, setHasVariations] = useState(false);

  // configs
  const { IS_WORDPRESS_CONNECTED, SEARCH_RESULTS_ALGOLIA: isAlgoliaEnabled } = useFeatureFlags();

  // Send Algolia Insights events only from search results page
  const shouldSendAlgoliaInsights = isAlgoliaEnabled;
  const isAfterSearch = !!algoliaQueryID;

  const productIdentifier = IS_WORDPRESS_CONNECTED ? product?.WP_id : product?.id;
  const wholesalerIdentifier = IS_WORDPRESS_CONNECTED ? product?.wholesaler?.wpId : product?.wholesaler?.id;

  // prices
  const [currencySymbol, setCurrencySymbol] = useState(currency?.symbol || '£');

  const [selectedWSP, setSelectedWSP] = useState(0);
  const [selectedRRP, setSelectedRRP] = useState(0);
  const [selectedDiscountPrice, setSelectedDiscountPrice] = useState(0);
  const [selectedMinOrderValue, setSelectedMinOrderValue] = useState(0);

  // wishlist data & handlers
  const [inWishlist, setInWishlist] = useState(false);

  useEffect(() => {
    const productInWishlist = wishlist?.filter((x) => x.productId === product?.WP_id).pop();

    if (productInWishlist) {
      setInWishlist(true);
    } else {
      setInWishlist(false);
    }
  }, [wishlist, product?.WP_id]);

  const handleAddToWishlist = async () => {
    if (isUnregistered) {
      handleShowSignupPopup();
    } else {
      await addToWishlist([{ productId: product.WP_id }]);
    }
    GTMProductWishlistEvents(
      product.productName,
      product.WP_id,
      selectedWSP,
      product.wholesaler?.name,
      `/product/${product.productSlug}`,
      `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
      product?.productCategories,
      userId
    );
  };

  const handleRemoveFromWishlist = async () => {
    await removeFromWishlist({ productId: product.WP_id });
  };

  // cart data & handlers
  const [stockError, setStockError] = useState([]);

  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const [inApiCall, setInApiCall] = useState(false);
  const [isSelectingQuantity, setIsSelectingQuantity] = useState(false);

  const [inCart, setInCart] = useState(false);
  const [cartItemId, setCartItemId] = useState(null);

  const [addToCartMinQuantity, setAddToCartMinQuantity] = useState(1);
  const [actualQuantity, setActualQuantity] = useState(null);
  const [previousQuantity, setPreviousQuantity] = useState(0);
  const [quantityToReset, setQuantityToReset] = useState(null);
  const [shouldTriggerCartApi, setShouldTriggerCartApi] = useState(false);

  const setProductInCartQuantities = (productInCart) => {
    setQuantityToReset(new Number(productInCart.quantity));
    setPreviousQuantity(productInCart.quantity);
    setActualQuantity(productInCart.quantity);
  };

  const resetCartQuantities = () => {
    setQuantityToReset(null);
    setActualQuantity(null);
    setPreviousQuantity(0);
  };

  const handleRedirectToRegister = async () => {
    const currentPath = router.asPath;
    await setRedirectUrl(currentPath);
    const registerUrl = `${WP_ROUTES.register}?${queryParamsString}`;
    router.push(registerUrl);
  };

  // initial setup
  useEffect(() => {
    const mHasVariations = product?.productVariationLabels && product?.productVariationLabels?.length > 0;
    setHasVariations(mHasVariations);

    const minQuantity = product?.productMinQuantity > 0 ? product.productMinQuantity : 1;
    setAddToCartMinQuantity(minQuantity);

    // only handle cart UI if it doesn't have variations
    if (!mHasVariations) {
      // cart dependencies
      if (cart) {
        const productInCart = cart?.filter((x) => x.productId === productIdentifier).pop();

        if (productInCart) {
          setInCart(true);
          setQuantityToReset(new Number(productInCart.quantity));
          setPreviousQuantity(productInCart.quantity);
          setActualQuantity(productInCart.quantity);

          if (productInCart.variationId) {
            setCartItemId(productInCart.variationId);
          } else {
            setCartItemId(productInCart.productId);
          }

          validateStockAmount({
            product,
            cartItemId: productInCart.productId,
            hasVariations,
            productQuantity: new Number(productInCart.quantity),

            handleStockError: setStockError,
          });
        } else {
          setInCart(false);
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  // react to currency change
  useEffect(() => {
    if (currency?.symbol) {
      setCurrencySymbol(currency.symbol);
    }
    if (currency?.name) {
      // WSP
      const wspInSelectedCurrency = product?.productPrice
        ?.filter((x) => x.productWSP > 0 && x.productCurrency === currency.name)
        .pop();

      if (wspInSelectedCurrency) {
        setSelectedWSP(Number(wspInSelectedCurrency.productWSP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productPrice,
            'productWSP',
            'productCurrency',
            currency.name,
            setSelectedWSP,
            exchangeRates
          );
        })();
      }

      // RRP
      const rrpInSelectedCurrency = product?.productPrice
        ?.filter((x) => x.productRRP > 0 && x.productCurrency === currency.name)
        .pop();

      if (rrpInSelectedCurrency) {
        setSelectedRRP(Number(rrpInSelectedCurrency.productRRP) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productPrice,
            'productRRP',
            'productCurrency',
            currency.name,
            setSelectedRRP,
            exchangeRates
          );
        })();
      }

      // discounted price
      const discountedPriceInSelectedCurrency = product?.productDiscount
        ?.filter((x) => x.productDiscountValue > 0 && x.productDiscountCurrency === currency.name)
        .pop();

      if (discountedPriceInSelectedCurrency) {
        setSelectedDiscountPrice(Number(discountedPriceInSelectedCurrency.productDiscountValue / 100));
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.productDiscount,
            'productDiscountValue',
            'productDiscountCurrency',
            currency.name,
            setSelectedDiscountPrice,
            exchangeRates
          );
        })();
      }

      // min order
      const minOrderInSelectedCurrency = product?.wholesaler?.minOrderValue
        ?.filter((x) => x.wholesalerStoreMinOrderAmount > 0 && x.wholesalerStoreMinOrderCurrency === currency.name)
        .pop();

      if (minOrderInSelectedCurrency) {
        setSelectedMinOrderValue(Number(minOrderInSelectedCurrency.wholesalerStoreMinOrderAmount) / 100);
      } else {
        (async () => {
          setPriceInDesiredCurrency(
            product?.wholesaler?.minOrderValue,
            'wholesalerStoreMinOrderAmount',
            'wholesalerStoreMinOrderCurrency',
            currency.name,
            setSelectedMinOrderValue,
            exchangeRates
          );
        })();
      }
    }
  }, [currency, product?.productDiscount, product?.productPrice, product?.wholesaler?.minOrderValue, exchangeRates]);

  // react to cart updates
  useEffect(() => {
    if (cart) {
      const productInCart = cart?.filter((x) => x.productId === productIdentifier).pop();

      if (productInCart) {
        setInCart(true);
        setProductInCartQuantities(productInCart);
        if (productInCart.variationId) {
          setCartItemId(productInCart.variationId);
        } else {
          setCartItemId(productInCart.productId);
        }
      } else {
        resetCartQuantities();
        setInCart(false);
      }
    }
  }, [cart, productIdentifier]);

  const handleOnAddToCart = async () => {
    setIsAddingToCart(true);
    setPopoverProduct(product);
    try {
      // passing productDetail and selectedProductId because for variation product need the details of parent product
      await addToCart({
        product,
        quantity: addToCartMinQuantity,
        selectedProductId: productIdentifier,
        queryId: algoliaQueryID,
      });
      GTMProductAddToCartEvents(
        product.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `/product/${product.productSlug}`,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        currency?.name,
        userId
      );
      if (shouldSendAlgoliaInsights) {
        const eventData = {
          objectID: product?.id,
          ...(algoliaQueryID && { queryID: algoliaQueryID }),
        };
        pushAlgoliaAddToCartEvent(eventData, isAfterSearch);
      }
      setShowAddToCartPopover(true);

      setPreviousQuantity(addToCartMinQuantity);
    } catch (error) {
      // error was already handled in cart provider
      // no UI resets needed
      setShowAddToCartPopover(false);
    } finally {
      setIsAddingToCart(false);
    }
  };

  const handleOnAddToCartWithVariation = () => {
    setIsAddingToCart(true);
    setPopoverProduct(product);
    setShowAddToCartPopover(true);
  };

  const handleProductAlreadyAddedToCart = () => {
    setPopoverProduct(product);
    setShowAddToCartPopover(true);
  };

  const handleAddToCartPopoverClose = () => {
    setShowAddToCartPopover(false);
  };

  const handleRemoveFromCart = async () => {
    setInApiCall(true);
    try {
      await removeFromCart({ itemId: cartItemId });
      // reset quantites
      resetCartQuantities();
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
      handleAddToCartPopoverClose();
    }
  };

  const handleIncreaseInCart = async (actual, previous) => {
    setInApiCall(true);
    try {
      const quantity = actual - previous;
      await increaseInCart({ itemId: cartItemId, quantity });
      setPreviousQuantity(actual);
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
    }
  };

  const handleDecreaseInCart = async (actual, previous) => {
    setInApiCall(true);
    try {
      const quantity = previous - actual;
      await decreaseInCart({ itemId: cartItemId, quantity });
      setPreviousQuantity(actual);
    } catch (error) {
      // reset quantity selector to previous state
      setQuantityToReset(new Number(previousQuantity));
    } finally {
      setInApiCall(false);
    }
  };

  const handleOnClickQuantitySelector = async ({ previous, actual }) => {
    if (shouldTriggerCartApi) {
      setIsSelectingQuantity(true);

      if (Number.isInteger(actual) && Number.isInteger(previous) && actual !== previous) {
        if (actual < addToCartMinQuantity) {
          await handleRemoveFromCart();
        } else if (actual > previous) {
          await handleIncreaseInCart(actual, previous);
        } else if (previous > actual) {
          await handleDecreaseInCart(actual, previous);
        }
      }

      setIsSelectingQuantity(false);
      setShouldTriggerCartApi(false);
    }
  };

  const quantitySelectorHelper = ({ actual }) => {
    validateStockAmount({
      product,
      cartItemId,
      hasVariations,
      productQuantity: actual,
      handleStockError: setStockError,
    });
    setActualQuantity(actual);
    setShouldTriggerCartApi(true);
  };

  useDebounce(async () => handleOnClickQuantitySelector({ actual: actualQuantity, previous: previousQuantity }), 400, [
    actualQuantity,
    previousQuantity,
  ]);

  const refProductsUrl = algoliaQueryID
    ? `/product/${product.productSlug}?query-id=${algoliaQueryID}`
    : `/product/${product?.productSlug}`;

  const handleRedirectToHref = () => {
    if (product?.productSlug) {
      GTMProductEvents(
        product?.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        `/product/${product?.productSlug}`,
        `/store/${product?.brandSlug}`,
        currency.name,
        'click',
        userId
      );

      router.push(refProductsUrl);
    }
  };

  const handleRedirectToBrand = () => {
    if (product?.brandSlug) {
      // const algoliaBrandClickEventParams = [
      //   'brand',
      //   {
      //     objectID: product?.wholesaler?.id,
      //     ...(algoliaQueryID && { queryID: algoliaQueryID }),
      //     position: index + 1,
      //   },
      //   isAfterSearch,
      // ];
      // Disabling algolia brand click event temporarily
      // if (shouldSendAlgoliaInsights) {
      //   pushAlgoliaClickEvent(...algoliaBrandClickEventParams);
      // }
      const brandCardLink = algoliaQueryID
        ? `/store/${product?.brandSlug}?query-id=${algoliaQueryID}`
        : `/store/${product?.brandSlug}`;
      router.push(brandCardLink);
    }
  };

  const handleOnLinkClick = useCallback(() => {
    handleItemIdChange(product?.id);
    if (product?.productSlug) {
      GTMProductEvents(
        product?.productName,
        productIdentifier,
        selectedWSP,
        product.wholesaler?.name,
        `${product?.productThumbnailUrl?.productThumbnailBaseUrl}/${product?.productThumbnailUrl?.productThumbnailName}`,
        `/product/${product?.productSlug}`,
        `/store/${product?.brandSlug}`,
        currency.name,
        'click',
        userId
      );
    }
    if (shouldSendAlgoliaInsights) {
      pushAlgoliaClickEvent(
        'product',
        {
          objectID: product?.id,
          ...(algoliaQueryID && { queryID: algoliaQueryID }),
          position: index + 1,
        },
        isAfterSearch
      );
    }
    setProductsVisited(product?.id);
    router.push(refProductsUrl);
  }, [
    handleItemIdChange,
    product?.productSlug,
    product?.productName,
    productIdentifier,
    selectedWSP,
    product?.wholesaler?.name,
    userId,
    product?.id,
    refProductsUrl,
    router,
    setProductsVisited,
  ]);

  const getConditionalWrapper = useCallback(
    (children) => (
      <Box
        onClick={() => {
          if (shouldSendAlgoliaInsights) {
            pushAlgoliaClickEvent(
              'product',
              {
                objectID: product?.id,
                ...(algoliaQueryID && { queryID: algoliaQueryID }),
                position: index + 1,
              },
              isAfterSearch
            );
          }
          handleShowSignupPopup();
        }}
      >
        {children}
      </Box>
    ),
    [handleShowSignupPopup]
  );

  const getDefaultWrapper = useCallback(
    (children) => (
      <Link
        aria-label={product.productSlug}
        data-testid="product-detail-link"
        underline="none"
        onClick={handleOnLinkClick}
        href={refProductsUrl}
        passHref
      >
        {children}
      </Link>
    ),
    [handleOnLinkClick, product?.productSlug, refProductsUrl]
  );

  const productSubtotal = () =>
    getProductSubtotal({
      quantity: actualQuantity,
      productId: productIdentifier,
      productPrice: selectedWSP * 100, // convert back to cents
      productDiscountedPrice: selectedDiscountPrice * 100, // convert back to cents
      productBulkDiscountMinOrderQuantity: product?.productBulkDiscount?.productBulkDiscountMinOrderQuantity,
      productBulkDiscountPercentage: product?.productBulkDiscount?.productBulkDiscountPercentage,
    });

  const wholesalerSummary = () => {
    const prodList = getCartItemsWithCommonWholesalerId({
      cart,
      wholesalerId: wholesalerIdentifier,
      currencyName: currency?.name,
    });

    // protect against empty cart
    if (!prodList?.length) {
      return { total: 0 };
    }

    return getWholesalerSummary(prodList, {
      percentage: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountPercentage ?? 0,
      minOrderAmount: product?.wholesaler?.storeDiscount[0]?.wholesalerStoreDiscountMinOrderAmount ?? 0,
    });
  };

  // product WSP
  let productPrice;
  const productPriceStyles = isMobile
    ? {
        priceStyle: { fontSize: '12px', lineHeight: '0.8rem', alignSelf: 'center', marginTop: '0' },
        priceTagClass: '',
        priceCent: {
          class: '',
          styles: {
            fontSize: '6px',
            alignSelf: 'center',
            background: 'linear-gradient(to left top, #f5000000 46%, #ff4842 46%, #ff4842 54%, #f5000000 54%)',
            textDecoration: '',
          },
        },
        splitPriceStyle: { fontSize: '8px' },
        boxDisplay: { display: 'flex' },
      }
    : {
        priceStyle: { marginTop: '-0.187rem' },
        priceTagClass: 'priceTag',
        priceCent: { class: 'priceCent', styles: {} },
        splitPriceStyle: {},
        boxDisplay: {},
      };
  if (hasVariations) {
    productPrice = (
      <Typography
        color="textPrimary"
        variant="body1"
        data-testid="wsp-typography"
        sx={{ ...productPriceStyles.priceStyle }}
      >
        <span className="notranslate">
          {' '}
          WSP from{' '}
          <span className={productPriceStyles.priceTagClass}>
            {currencySymbol}
            {splitPrice(selectedWSP).price}.
            <span className={productPriceStyles.priceCent.class}>{splitPrice(selectedWSP).floating}</span>
          </span>
        </span>
      </Typography>
    );
  } else if (selectedDiscountPrice) {
    productPrice = (
      <Box sx={productPriceStyles.boxDisplay}>
        <Typography
          component="span"
          color="error"
          variant="body1"
          data-testid="wsp-flair-typography"
          sx={{ pr: '2ch', ...productPriceStyles.priceStyle }}
        >
          <span className="notranslate">
            {' '}
            WSP{' '}
            <span className="priceTag">
              {currencySymbol}
              {splitPrice(selectedDiscountPrice).price}.
              <span className="priceCent" style={productPriceStyles.splitPriceStyle}>
                {splitPrice(selectedDiscountPrice).floating}
              </span>
            </span>
          </span>
        </Typography>
        <Typography
          {...(splitPrice(selectedDiscountPrice).price?.length >= 2 ? {} : { component: 'span' })}
          color="textPrimary"
          variant="body2"
          data-testid="wsp-typography"
          sx={{ ...styles.strike, ...productPriceStyles.priceCent.styles }}
        >
          <span className="notranslate">
            <span className="priceTag">
              {currencySymbol}
              {splitPrice(selectedWSP).price}.<span className="priceCent">{splitPrice(selectedWSP).floating}</span>
            </span>
          </span>
        </Typography>
      </Box>
    );
  } else {
    productPrice = (
      <Typography
        color="textPrimary"
        variant="body1"
        data-testid="wsp-typography"
        sx={{ marginTop: '-0.187rem', ...productPriceStyles.priceStyle }}
      >
        <span className="notranslate">
          WSP{' '}
          <span className="priceTag">
            {currencySymbol}
            {splitPrice(selectedWSP).price}.
            <span className={productPriceStyles.priceCent.class}>{splitPrice(selectedWSP).floating}</span>
          </span>
        </span>
      </Typography>
    );
  }

  // buttons
  const unAuthenticatedButton = (
    <Button
      variant="contained"
      color="primary"
      onClick={handleRedirectToRegister}
      disabled={isAddingToCart}
      data-testid="register-button"
    >
      Register to see price
    </Button>
  );

  const ProductCardLayoutProps = {
    sx,
    product,
    index,
    popoverProduct,
    addToCartButtonRef,
    currencySymbol,
    productPrice,
    unAuthenticatedButton,
    selectedRRP,
    quantityToReset,
    selectedMinOrderValue,
    conditionalWrapConfig: {
      condition: isUnregistered && showSignupPopup,
      conditionalWrapper: getConditionalWrapper,
      defaultWrapper: getDefaultWrapper,
    },
    inCart,
    inApiCall,
    inWishlist,
    stockError,
    setStockError,
    hasVariations,
    isAddingToCart,
    isAuthenticated,
    isSelectingQuantity,
    showAddToCartPopover,
    productSubtotal,
    handleOnAddToCart,
    wholesalerSummary,
    handleAddToWishlist,
    handleRedirectToHref,
    handleRemoveFromCart,
    handleRedirectToBrand,
    quantitySelectorHelper,
    handleRemoveFromWishlist,
    handleAddToCartPopoverClose,
    handleOnAddToCartWithVariation,
    handleProductAlreadyAddedToCart,
    algoliaQueryID,
  };

  // Full UI
  if (!loading) {
    return !isMobileLayout ? (
      // desktop UI
      <LegacyProductCardDesktopLayout {...ProductCardLayoutProps} />
    ) : (
      // mobile UI
      <LegacyProductCardMobileLayout {...ProductCardLayoutProps} />
    );
  }

  // Loading
  return (
    <Card
      sx={{
        ...styles.root,
        ...sx,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      }}
    >
      {isAuthenticated ? (
        <>
          <Skeleton
            variant="rectangular"
            width="100%"
            sx={{
              [theme.breakpoints.up('sm')]: {
                height: '314',
              },
              [theme.breakpoints.down('sm')]: {
                height: '44vw',
              },
            }}
          />
          <Skeleton
            variant="text"
            sx={{
              [theme.breakpoints.up('sm')]: {
                mx: 1,
                height: '50px',
              },
              [theme.breakpoints.down('sm')]: {
                mx: 0.5,
                height: 24,
              },
            }}
          />
          <CardHeader
            sx={{
              ...styles.brandArea,
              [theme.breakpoints.down('sm')]: {
                padding: 0.5,
              },
            }}
            avatar={
              <Skeleton
                animation="wave"
                variant="circular"
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    height: 50,
                    width: 50,
                    mb: 1,
                  },
                  [theme.breakpoints.down('sm')]: {
                    height: 32,
                    width: 32,
                  },
                }}
              />
            }
            title={
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ [theme.breakpoints.up('sm')]: { width: '100%', height: 30 } }}
              />
            }
            subheader={
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  width: '40%',
                  [theme.breakpoints.up('sm')]: { mb: 2, height: 16 },
                  [theme.breakpoints.down('sm')]: {
                    height: 14,
                  },
                }}
              />
            }
          />
          <CardContent
            sx={{
              ...styles.actions,
              [theme.breakpoints.down('sm')]: {
                m: 0,
                p: 0.5,
                flexDirection: 'column',
                '&:last-child': {
                  pb: 0.5,
                },
              },
            }}
          >
            <CardContent
              sx={{
                [theme.breakpoints.down('sm')]: {
                  p: 0,
                  display: 'flex',
                  justifyContent: 'space-between',
                },
              }}
            >
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  [theme.breakpoints.up('sm')]: { marginTop: '-1rem', height: 20, width: 60 },
                  [theme.breakpoints.down('sm')]: {
                    width: '5ch',
                  },
                }}
              />
              <Skeleton
                animation="wave"
                variant="text"
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    width: 30,
                    height: 11,
                  },
                  [theme.breakpoints.down('sm')]: {
                    width: '4ch',
                  },
                }}
              />
            </CardContent>
            <Skeleton
              variant="rectangular"
              sx={{
                height: 36,
                [theme.breakpoints.up('sm')]: {
                  width: 120,
                },
                [theme.breakpoints.down('sm')]: {
                  mt: 0.5,
                  height: 32,
                  width: '100%',
                  borderRadius: '10px',
                },
              }}
            />
          </CardContent>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" width="100%" height="314" />
          <CardTitle>
            <Skeleton variant="text" height="30px" sx={{ mx: 0.5, mt: -0.75 }} />
            <Skeleton variant="text" height="30px" sx={{ mx: 0.5 }} />
          </CardTitle>
          <CardContent>
            <Skeleton animation="wave" bgvariant="rectangular" height={60} width={205} sx={{ mt: -0.5, mx: 'auto' }} />
          </CardContent>
        </>
      )}
    </Card>
  );
};

LegacyProductCard.propTypes = {
  sx: PropTypes.shape({}),
  forceDesktop: PropTypes.bool,
  index: PropTypes.number.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string,
    WP_id: PropTypes.string,
    flair: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.string,
      }),
    ]),
    productSlug: PropTypes.string,
    brandSlug: PropTypes.string,
    productMinQuantity: PropTypes.number,
    productPrice: PropTypes.arrayOf(
      PropTypes.shape({
        productRRP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productWSP: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        productCurrency: PropTypes.string,
      })
    ),
    productDiscount: PropTypes.arrayOf(
      PropTypes.shape({
        productDiscountValue: PropTypes.number,
        productDiscountCurrency: PropTypes.string,
      })
    ),
    productCategories: PropTypes.arrayOf(PropTypes.string),
    productThumbnailUrl: PropTypes.shape({
      productThumbnailName: PropTypes.string,
      productThumbnailBaseUrl: PropTypes.string,
    }),
    productVariationLabels: PropTypes.arrayOf(PropTypes.string),
    productName: PropTypes.string.isRequired,
    wholesaler: PropTypes.shape({
      name: PropTypes.string,
      country: PropTypes.string,
      description: PropTypes.string,
      rating: PropTypes.string,
      id: PropTypes.string,
      wpId: PropTypes.string,
      logo: PropTypes.shape({
        wholesalerStoreLogoImage: PropTypes.string,
        wholesalerStoreLogoBasePath: PropTypes.string,
      }),
      minOrderValue: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreMinOrderAmount: PropTypes.number,
          wholesalerStoreMinOrderCurrency: PropTypes.string,
        })
      ),
      storeDiscount: PropTypes.arrayOf(
        PropTypes.shape({
          wholesalerStoreDiscountPercentage: PropTypes.number,
          wholesalerStoreDiscountMinOrderAmount: PropTypes.number,
        })
      ),
    }),
    productBulkDiscount: PropTypes.shape({
      productBulkDiscountPercentage: PropTypes.number,
      productBulkDiscountMinOrderQuantity: PropTypes.number,
    }),
    variations: PropTypes.arrayOf(
      PropTypes.shape({
        WP_id: PropTypes.string,
        attributes: PropTypes.arrayOf(
          PropTypes.shape({
            productVariationLabels: PropTypes.arrayOf(PropTypes.string),
            productVariationValues: PropTypes.arrayOf(PropTypes.string),
            productMinQuantity: PropTypes.number,
            productLongDescription: PropTypes.string,
            productThumbnailUrl: PropTypes.shape({
              productThumbnailName: PropTypes.string,
              productThumbnailBaseUrl: PropTypes.string,
            }),
            productPrice: PropTypes.arrayOf(
              PropTypes.shape({
                productRRP: PropTypes.number,
                productWSP: PropTypes.number,
                productCurrency: PropTypes.string,
              })
            ),
            productDiscount: PropTypes.arrayOf(
              PropTypes.shape({
                productDiscountValue: PropTypes.number,
                productDiscountCurrency: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
    productInventory: PropTypes.shape({
      productStock: PropTypes.number,
      productInventoryTracked: PropTypes.string,
    }),
  }),
  loading: PropTypes.bool,
  handleItemIdChange: PropTypes.func,
  algoliaQueryID: PropTypes.string,
};

export default LegacyProductCard;
